import React from "react";
import Basic from "./Basic/Basic";
import { Typography, Grid, Box } from "@mui/material";
import Banner from "./Banner";
import LatestImage from "../Assets/GalleryImages/Gallery0.jpeg"

const Activity = () => {
  // const cardTypo1Animation = keyframes`
  //   0% {
  //     transform: translateX(100%);
  //   }
  //   100% {
  //     transform: translateX(-100%);
  //   }
  // `;
  const styles = {
    cardSize: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "30px 0",
      padding: "20px 15px",
      cursor: "pointer",
      textAlign: "start",
      backgroundColor: "#f9f9f9",
      "&:hover": {
        boxShadow: "2px 3px 11px 4px rgba(248, 237, 198, 0.86)",
      },
    },
    cardTypo1: {
      fontWeight: 600,
      fontSize: "20px",
      color: "#f69740",
      textAlign: { lg: "center" },
      padding: "0 5%",
      // animation: `${cardTypo1Animation} 20s infinite linear`,
    },
    cardTypo2: {
      fontWeight: 600,
      fontSize: "16px",
      textAlign: { lg: "center" },
      margin: "5px 0px",
      padding: "0 7%",
    },
    cardTypo3: {
      fontWeight: 600,
      fontSize: "16px",
      textAlign: { lg: "center" },
      marginBottom: "20px",
    },
    cardBox2Typo: {
      display: "flex",
      justifyContent: "space-between",
      width: { lg: "70%" },
      margin: { lg: "0 auto" },
    },
    typoWidth: {
      width: { lg: "70%" },
      margin: { lg: "0 auto" },
    },
    typoFont: {
      fontSize: "18px",
    },
  };
  return (
    <Basic>
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            // display: { xs: "none", sm: "block" },
            marginTop: { xs: "4rem" },
          }}
        >
          <Banner />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            // textAlign="center"
            variant="h4"
            sx={{
              fontFamily: "Libre Baskerville,serif",
              fontWeight: 600,
              fontSize: "50px",
              color: "rgb(251,151,63)",
              marginTop: "1rem",
            }}
          >
           Activity
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ margin: "30px" }}
        >
          {/* <Card sx={styles.cardSize}> */}
          <Box>
            <Typography sx={styles.cardTypo1}>
            This section highlights the cultural and social gatherings of the Pushkarna community, bringing people together to celebrate traditions and special moments.
            </Typography>
            <Typography sx={styles.cardTypo2}>
            The Holi Milan event was full of colors, joy, and togetherness, as people celebrated the festival with enthusiasm. Alongside this, the Women's Day celebration honored the achievements of women, recognizing their contributions and empowering them. Both events reflected the strong community spirit and unity.
            Alongside this, the Women's Day celebration honored the achievements of women, featuring inspiring speeches, cultural performances, and discussions on empowerment. The event highlighted the vital role of women in society and encouraged equality and respect.
            </Typography>
            {/* <Typography sx={styles.cardTypo3}>
              Akhil Bhartiya Pushtikar sewa Parishad
            </Typography> */}
          </Box>
          
          {/* </Card> */}
        </Grid>
      </Grid>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item sx={{ marginBottom: "50px" }}>
          <Box>
            <img src={LatestImage} alt="PriorityOne" height="100%" width="100%" />
          </Box>
        </Grid>
        {/* <Grid item sx={{ marginBottom: "50px" }}>
          <Box>
            <img src={ReplacePriorityOne} alt="PriorityOne" height="100%" width="100%" />
          </Box>
        </Grid>
        <Grid item sx={{ marginBottom: "50px" }}>
          <Box>
            <img src={priorityOneS} alt="priorityOneS" height="100%" width="100%" />
          </Box>
        </Grid>
        <Grid item sx={{ marginBottom: "50px" }}>
          <Box>
            <img src={PrioritySecond} alt="PrioritySecond" height="100%" width="100%" />
          </Box>
        </Grid>
        <Grid item sx={{ marginBottom: "50px" }}>
          <Box>
            <img src={ImageThird} alt="ImageThird" height="100%" width="100%" />
          </Box>
        </Grid>
        <Grid item sx={{ marginBottom: "50px" }}>
          <Box>
            <img src={ImageSecond} alt="ImageSecond" height="100%" width="100%" />
          </Box>
        </Grid>
        <Grid item sx={{ marginBottom: "50px" }}>
          <Box>
             <img src={ImageOne} alt="ImageOne" height="100%" width="100%" />
          </Box>
        </Grid>
        <Grid item sx={{ marginBottom: "50px" }}>
          <Box>
            <img src={ImageOne} alt="ImageOne" height="100%" width="100%" />
          </Box>
        </Grid> */}
        {/* <Grid
          item
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          <iframe
            title="PDF Viewer"
            src={`https://docs.google.com/gview?url=${"https://intranet.mobiloittegroup.com/uploads/estimations/uploads/ABPSP.pdf"}&embedded=true`}
            // src="https://intranet.mobiloittegroup.com/uploads/estimations/uploads/ABPSP.pdf"
            width="100%"
            height="650px"
          />
        </Grid> */}
      </Grid>
    </Basic>
  );
};

export default Activity;

// const Newselter = () => {
//   const underlineTransition = {
//     position: "relative",
//     "&::after": {
//       content: '""',
//       position: "absolute",
//       bottom: 0,
//       left: 0,
//       width: "5%",
//       height: "2px",
//       backgroundColor: "rgb(251,151,63)", // Change color as needed
//       transition: "width 0.3s ease-in-out", // Transition effect
//     },
//     "&:hover::after": {
//       width: "35%", // Expand underline on hover
//       "@media (min-width:600px)": {
//         width: "19%", // Adjust for different screen sizes if needed
//       },
//       "@media (min-width:960px)": {
//         width: "23%", // Adjust for different screen sizes if needed
//       },
//       "@media (min-width:1280px)": {
//         width: "19%", // Adjust for different screen sizes if needed
//       },
//     },
//   };
//   return (
//     <>
//       <Grid container spacing={1}>
//         <Grid item xs={12}>
//           <Typography
//             textAlign="center"
//             marginBottom="15px"
//             variant="h4"
//             sx={{ fontFamily: "Libre Baskerville,serif", color: "#3f51b5",marginTop:{xs:"3rem"}}}
//           >
//             Newsellter
//           </Typography>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={6}
//           sx={{
//             order: { xs: 2, md: 1 },
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Box sx={{ width: "80%", height: "95%",marginLeft:{md:"-6rem", xs:"0rem"},boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.9)", borderRadius: "0.5rem" }}>
//             <img
//               src={Download}
//               alt="Aestronote"
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//                 borderRadius: "0.5rem",
//               }}
//             />
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
//         <Box sx={{width:"100%", height:"100%" ,marginLeft:{md:"-2rem", xs:"0rem"}}}>

//           <Typography
//             variant="h4"
//             sx={{
//               fontFamily: "Libre Baskerville,serif",
//               marginLeft: "1rem",
//               marginTop: "2rem",
//               ...underlineTransition,
//             }}
//           >

//             Newsletter
//           </Typography>
//           <Typography variant="body1" sx={{ padding: 2, textAlign: "justify" }}>
//             Hinduism is one of the world's oldest religions, often regarded as a
//             way of life or a cultural identity as much as it is a religious
//             belief system. It originated in the Indian subcontinent and is
//             diverse, encompassing a wide spectrum of beliefs, rituals,
//             practices, and philosophies.n
//           </Typography>
//           <Typography variant="body1" sx={{ padding: 2, textAlign: "justify" }}>
//             Hinduism is one of the world's oldest religions, often regarded as a
//             way of life or a cultural identity as much as it is a religious
//             belief system. It originated in the Indian subcontinent and is
//             diverse, encompassing a wide spectrum of beliefs, rituals,
//             practices, and philosophies.n
//             Hinduism is one of the world's oldest religions, often regarded as a
//             way of life or a cultural identity as much as it is a religious
//             belief system. It originated in the Indian subcontinent and is
//             diverse, encompassing a wide spectrum of beliefs, rituals,
//             practices, and philosophies.n
//           </Typography>
//           </Box>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default Newselter;
