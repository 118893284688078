export const FindalPDFOCTNOV = "Final_PDF_P_Sandesh_Oct_Nov_21_F.pdf";
export const OCTNOV23 = "October-November_23_P_Sandesh.pdf";
export const AUGSEP2023 = "P_Sandesh_Aug_-_Sept_2023_Final_pdf.pdf";
export const FINALPDFMAY = "P_Sandesh_Final_PDF_May_23.pdf";
export const JULYAUG22 = "P_Sandesh_July_August_22.pdf";
export const MAYJUNE22 = "P_Sandesh_May_June_22_Final_PDF.pdf";
export const NOVDEC22 = "P_Sandesh_Nov_dec_2022_F.pdf";
export const MARCHAPRILE22 = "Pushkarana_sadesh_March_April_2022_Final_F.pdf";
export const FINALDEC22 = "Pushkarana_Sandesh_Final_Dec_21-Jan_22_F.pdf";
export const HUNEJULY23 = "pushkarana_Sandesh_June_July_23.pdf";
export const FINALFAV22 = "Pushkarna_Sandesh_Feb_2022_Final_F.pdf";
export const AUGSEPT14 = "Sandesh_By_AArti_Aug_Sept_20214.pdf";
export const SEPOCT22 = "Sept_Oct_2022_P_Sandesh_combinedPDF.pdf";
export const SEPT2019 = "Sandesh_sept_2019.pdf";
export const NOVDEC19 = "Sandesh_Nov_Dec_2019.pdf";
export const FEB2020 = "Puskarna_Sandesh_Feb_2020.pdf";
export const JAN2020 = "P_Sandesh_Jan_2020.pdf";
export const FEB2024 = "P_Sandesh_Feb_2024.pdf";
export const JAN2024 = "Sandesh_Dec_23_Jan_24_final_pdf.pdf";
export const APRIL2024 = "P._Sande29524_11zon.pdf";
export const MAY2024 = "P._Sandesh_May_24_Final_pdf_11zon_(2).pdf";
export const JUNE2024 = "Pushkarna-3.pdf";
export const JULY2024 = "P-4.pdf";
export const AUGUST2024 = "P_Sandesh_August_2024_Fifnal_pdf.1.pdf";
export const FORM = "P-11.pdf";
export const NEWS = "Pushkarna-2.pdf";
export const September2024 = "PSandeshSept24.pdf";
export const October2024 = "P_Sandesh_Oct_2024_F..pdf";
export const November2024 = "Pushkarna_Sandesh_Nov_2024.pdf";
export const December2024 = "Pushkarna_Sandesh_Dec_2024.pdf";
export const January2025 = "Pushkarna_Sandesh_Jan_2025.pdf";
export const February2025 = "Pushkarna_Sandesh_Feb_20251.pdf";
