import React, { useState } from "react";
import Link from "@mui/material/Link";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../Assets/mataLogo1.png";

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    { text: "Home", path: "/dashboard" },
    { text: "About Us", path: "/aboutus" },
    { text: "Pushkarana Sandesh", path: "/pushkaranasandesh" },
    { text: "Gallery", path: "/gallery" },
    { text: "Activity", path: "/activity" },
    { text: "Advertise with Us", path: "/#" },
    { text: "Contact Us", path: "/contactus" },
    { text: "Correspondence", path: "/correspondence" },
  ];

  const drawerContent = (
    <Box sx={{ textAlign: "center", background: "rgb(251,151,63)" }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              sx={{
                textAlign: "center",
              }}
            >
              <Link
                href={item.path}
                onClick={handleDrawerToggle}
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                <ListItemText primary={item.text} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        sx={{
          background:
            "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
          color: "#000",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none", xs: "block" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="Banner"
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
              }}
            />
            {/* <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "block" },
                fontSize: "25px",
              }}
            >
              Pushkarana
            </Typography> */}
          </Box>

          {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.text}
                href={`#${item.path}`}
                sx={{
                  color: "#000",
                  
                  "&:hover": {
                    color: "#FFF",
                    backgroundColor: "transparent",
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box> */}
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            {navItems.map((item) => (
              <Link
                key={item.text}
                href={item.path}
                style={{ textDecoration: "none" }}
                // spy={true}
                // smooth={true}
                // offset={-70} // Adjust the offset as needed based on your layout
                // duration={500} // The duration of the scroll animation
                // activeClass="active" // Add this class to the active link
              >
                <Button
                  sx={{
                    color: "#000",
                    fontSize: "15px",
                    "&:hover": {
                      color: "#FFF",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {item.text}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "240px",
              background:
                "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
              color: "#000",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Navbar;
