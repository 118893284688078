import React from "react";
import { Routes, Route } from "react-router-dom";
import DashBoard from "../Components/DashBoard";
import Contact from "../Components/Contact";
import Correspondence from "../Components/Correspondence";
import About from "../Components/About";
import Pushkarana from "../Components/Pushkarana";
import Gallery from "../Components/Gallery";
import Activity from "../Components/Activity"
  
const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<DashBoard />}></Route>
      <Route path="/dashboard" element={<DashBoard />}></Route>
      <Route path="/contactus" element={<Contact />}></Route>
      <Route path="/pushkaranasandesh" element={<Pushkarana />}></Route>
      <Route path="/aboutus" element={<About />}></Route>
      <Route path="/correspondence" element={<Correspondence />}></Route>
      <Route path="/gallery" element={<Gallery />}></Route>
      <Route path="/activity" element={<Activity />}></Route>
    </Routes>
  );
};

export default Routing;
